export default function (canvas: HTMLCanvasElement, type?: string): Promise<Blob> {
  // Promise
  return new Promise((resolve) => {
    // To blob
    canvas.toBlob(blob => {
      // If blob
      if (blob) resolve(blob)
        
    }, type)

  })

}