<template>
  <div class="gap-2.5 grid grid-cols-[auto_1fr]">
    <input type="checkbox" class="appearance-none border border-grayble check:border-current checked:bg-current cursor-pointer h-10 rounded-none w-10" @input="handleInput" :value="context._value" />

    <div class="leading-snug text-[10px] uppercase">
      <component v-if="context.slots.default" :is="context.slots.default"></component>
    </div>
  </div>
</template>

<script lang="ts" setup>
// Props
const props = defineProps({
  context: {
    type: Object,
    required: true
  }
})

// Handle input
// ----------
function handleInput(e: any) {
  // Update value
  props.context.node.input(e.target.checked)

}
</script>