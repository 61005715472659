export default function (url: string): Promise<HTMLImageElement> {
  // Promise
  return new Promise((resolve, revoke) => {
    // Image
    const img = new Image()
    
    // On load
    img.onload = () => {
      // Resolve
      resolve(img)
      
    }
    
    // Cross origin
    img.crossOrigin = 'anonymous'
    
    // Source
    img.src = url
    
  })

}